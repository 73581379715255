import React from "react"
import Reveal from "react-awesome-reveal"
import { keyframes } from "@emotion/react"
import parse from "html-react-parser"
import * as S from "./fade-title.styles"

export const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -60px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`
const FadeTitle = ({ title, isDark = true }) => {
  return (
    <Reveal keyframes={customAnimation} triggerOnce={true}>
      <S.Title isDark={isDark}>{parse(title)}</S.Title>
    </Reveal>
  )
}

export default FadeTitle
